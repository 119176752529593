export default{

    jsApiCall:function(data){
    WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        data,
        function(res){
            WeixinJSBridge.log(res.err_msg);
            if(res.err_msg=='get_brand_wcpay_request:ok'){
                // location.reload();
            }else{
                // location.href="/#/payfail"
            }
        }
    )
    },

     callpay:function(data){
        if (typeof WeixinJSBridge == "undefined"){
            if( document.addEventListener ){
                document.addEventListener('WeixinJSBridgeReady', jsApiCall, false);
            }else if (document.attachEvent){
                document.attachEvent('WeixinJSBridgeReady', jsApiCall);
                document.attachEvent('onWeixinJSBridgeReady', jsApiCall);
            }
        }else{
            // jsApiCall(data);
            this.jsApiCall(data)
        }
    }
}