<template>
  <div class="boss">
    <!-- header -->
    <div class="header">
      <div class="header-box">
        <div class="divp">
          <p class="p1" v-if="keyframesShow">
            <span>报</span>
            <span>告</span>
            <span>查</span>
            <span>询</span>
            <span>中</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </p>
          <p class="p1" v-if="!keyframesShow">扫描完成！</p>

          <p class="p2" v-if="!keyframesShow">
            已有
            <span style="color: #f8a500">3600万</span
            >人<br />成功解锁个人报告<br />平均每人有<span
              style="color: #f8a500"
              >五项</span
            >风险
          </p>
        </div>
        <div class="imgbox">
          <img src="../assets/image/xz2.png" alt="" />
          <p class="dwp">{{ time }}%</p>
        </div>
      </div>
      <div class="report" v-if="!keyframesShow">
        支付解锁完整个人数据报告 合理优化个人风险
      </div>
    </div>
    <!-- 第二部分 -->
    <div class="dwitem">
      <div class="swperitem">
        <van-notice-bar
          left-icon="volume-o"
          :scrollable="false"
          background="#fff6ef"
          color="#ff5800"
        >
          <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="3000"
            :show-indicators="false"
          >
            <van-swipe-item v-for="(item, index) in xxdd" :key="index">{{
              item.text
            }}</van-swipe-item>
          </van-swipe>
        </van-notice-bar>
      </div>
      <div class="userData">
        <p>
          姓名：{{ userName || '- - -' }}&emsp;年龄：{{
            userData.age || '- - -'
          }}
          &emsp; 性别：{{ userData.sex || '- - -' }}
        </p>
        <p>户籍所在地：{{ userData.address || '- - -' }}</p>
      </div>
      <!-- 报告样例 和 在线客服 -->
      <div class="example">
        <p @click="sampleReport">样例报告</p>
        <p @click="onlineService">在线客服</p>
      </div>
      <!-- 里面的内容 -->
      <div class="cntboss">
        <div class="cntbox" v-for="(item, index) in jiesuo" :key="index">
          <div class="cntboxl">
            <img src="../assets/image/gth.png" alt="" />
            {{ item.title }}
            <span style="color: #ccc; margin-left: 5px">{{ item.span }}</span>
          </div>
          <div class="cntboxr" @click="payment">
            <img src="../assets/image/suo.png" alt="" />
            立即解锁
          </div>
        </div>
        <div class="more" @click="payment">更多模块立即解锁>></div>
      </div>
    </div>
    <!-- 支付 -->
    <van-button class="payment" @click="payment" :disabled="keyframesShow"
      >一键解锁所有模块</van-button
    >
    <div class="footer">V1.1.4</div>
    <!-- 弹出层 -->
    <van-overlay :show="show" position="center">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="block1">
            <p>选择支付方式</p>
            <van-icon name="cross" @click="showBtn" size="20" />
          </div>
          <div class="block2" @click="zfbPay" v-if="zfbPayStatus">
            <img src="../assets/image/zfb.png" alt="" />
            <div class="block2_r">
              <!--  <img src="../assets/image/tj.png" alt=""> -->
              <div class="block2_r_one">支付宝支付</div>
              <div class="block2_r_two">
                <img src="../assets/image/sd.png" alt="" />随机减免<span
                  style="color: red"
                ></span>
              </div>
            </div>
          </div>
          <div class="block3" @click="wxPay" v-if="vxpayshow && wxPayStatus">
            <img src="../assets/image/wx.png" alt="" />
            <div class="block3_r">
              <div class="block3_r_one">微信支付</div>
              <div class="block3_r_two">
                <img src="../assets/image/sd.png" alt="" />暂无优惠
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>

    <van-dialog
      v-model="showDialo"
      title="您将错过以下检测"
      @confirm="jxcxbtn"
      cancelButtonText="放弃查看"
      confirmButtonText="继续查询"
      confirmButtonColor="#078bfd"
      show-cancel-button
    >
      <div class="dialogbox">
        <div>
          <img src="../assets/image/hit_full1.png" alt="" />
          <p>命中风险</p>
        </div>
        <div>
          <img src="../assets/image/hit_full2.png" alt="" />
          <p>多头借贷记录</p>
        </div>
        <div>
          <img src="../assets/image/hit_full3.png" alt="" />
          <p>失信欺诈记录</p>
        </div>
      </div>
      <div class="dialogboxText">
        立即打赏检测更多个人信息，预防个人信用风险
      </div>
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import { Toast, Dialog, CountDown } from 'vant';
Vue.use(Dialog);
Vue.use(CountDown);

import {
  tradePay,
  openId,
  payWay,
  latestOrderId,
  appId,
  genOrderNo,
} from '../api/getUserInfo';
import pay from '../api/pay';
import { getCardInfo } from '../api/getCardInfo';
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      show: false,
      showDialo: false,
      time: 0,
      xxdd: [
        { text: '80%用户已解锁，平均有6项风险' },
        { text: '尾号7378已解锁，有6项风险' },
        { text: '尾号3997已解锁，暂无风险' },
        { text: '尾号1384已解锁，有8项风险' },
        { text: '尾号1106已解锁，有9项风险' },
        { text: '尾号2927已解锁，有3项风险' },
      ],
      jiesuo: [
        { title: '身份信息冒用检测 ', span: ' ------------------' },
        // {title:"失信欺诈记录" ,span:" ------------------------"},
        { title: '信用等级评分 ', span: ' -----------------------' },
        { title: '多机构风险筛查 ', span: ' ---------------------' },
        { title: '借贷异常行为', span: ' ------------------------' },
        // {title:"贷款逾期信息检测 ",span:" ------------------"},
        { title: '银行类网贷行为查询 ', span: ' ---------------' },
        { title: '欺诈风险评估 ', span: ' ------------------------' },
        { title: 'P2P类网贷行为查询 ', span: ' ----------------' },
        { title: '司法涉诉查询 ', span: ' ------------------------' },
        // {title:"联系人黑名单检测 ",span:" ------------------"},
        { title: '个人信用优化方案 ', span: ' -------------------' },
      ],
      keyframesShow: true,
      payInfo: null,
      zybhtml: null,
      url: null,
      wxPayUrl: null,
      vxpayshow: true,
      wxPayStatus: true,
      zfbPayStatus: true,
      appid: 'wx1bcf184f43294057',
      userData: {
        age: null,
        sex: null,
        address: null,
      },
      userName: '- - -',
      fishTime: null,
      idCx: true,
      bd_vid: localStorage.getItem('bd_vid') || '',
      isAlipay: false,
      zfbFlag: null,
    };
  },
  created() {
    //支付宝环境下
    const userAgent = navigator.userAgent.toLowerCase();
    this.isAlipay = userAgent.indexOf('alipay') !== -1;
    this.zfbFlag = this.isAlipay ? 1 : null;
    this.payInfo = this.$route.query;
    if (this.$route.query) {
      let url = '?';
      for (let key in this.$route.query) {
        url += `${key}=${this.$route.query[key]}&`;
      }
      this.url = url;
      this.wxPayUrl = url;
    }
  },
  mounted() {
    // console.log(this.bd_vid);
    this.payWay({ cid: this.$route.query.cid });
    this.fishTime = setInterval(() => {
      // this.latestOrderId({ userId: this.$route.query.userId });
    }, 600);

    let timer = setInterval(() => {
      if (this.time == 100) {
        this.userName = this.$route.query.userName || '******';
        let idCard = this.$route.query.idCard;
        this.userData = getCardInfo(idCard);
        this.keyframesShow = false;
        clearInterval(timer);
        return;
      } else {
        this.time++;
      }
    }, 25);
  },
  beforeRouteLeave(to, from, next) {
    next();
    clearInterval(this.fishTime);
    this.fishTime = null;
    Toast.clear();
  },
  methods: {
    // 一键解锁所有模块
    async payment() {
      if (!this.zfbPayStatus && !this.wxPayStatus) {
        Toast('暂时不能一键解锁');
        return;
      }
      let isMobile = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      if (isMobile) {
        // 是手机
        this.show = true;
      } else {
        // 是网页 直接支付宝支付
        try {
          let result = await genOrderNo(this.payInfo);
          if (result.data.code == 200) {
            // 是网页
            if (this.$route.query.cid == '928' && this.bd_vid) {
              this.payInfo.logidUrl = localStorage.getItem('logidUrl');
            }
            this.payInfo.orderNo = result.data.data;
            this.payInfo.payType = 1;
            this.payInfo.zfbFlag = this.zfbFlag;
            this.pay(this.payInfo);
          }
        } catch (error) {
          Toast(error);
        }
      }
    },
    payWay(data) {
      payWay(data).then((res) => {
        if (res.data.code == 200) {
          this.wxPayStatus = res.data.data.wxPayStatus;
          this.zfbPayStatus = res.data.data.zfbPayStatus;
          if (this.wxPayStatus) {
            // // 获取商户的appid
            // this.getAppId();
            // 根据appid生成code码获取openid
            this.getWxOpenId();
          }
        }
      });
    },
    // 根据userid 查询orderNO
    latestOrderId(data) {
      latestOrderId(data).then((res) => {
        if (res.data.code == 200) {
          Toast.loading({
            message: '报告生成中...',
            forbidClick: true,
          });
          // 1是百融的接口 success页面
          if (res.data.data.porduct == 1) {
            setTimeout(() => {
              this.$router.push({
                path: '/success',
                query: {
                  orderNo: res.data.data.orderNo,
                  cid: res.data.data.cid,
                  templateId: res.data.data.templateId,
                },
              });
            }, 1000);
          } else {
            // 2 是天创的接口 显示天创的页面
            // res.data.data.porduct==2
            setTimeout(() => {
              this.$router.push({
                path: '/prosperity',
                query: {
                  orderNo: res.data.data.orderNo,
                  cid: res.data.data.cid,
                  templateId: res.data.data.templateId,
                },
              });
            }, 1000);
          }
        } else {
          Toast.clear();
        }
      });
    },
    sampleReport() {
      this.$router.push({
        path: '/example',
      });
    },
    onlineService() {
      this.$router.push({
        path: '/customerService',
      });
    },
    async zfbPay() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('micromessenger') != -1) {
        console.log('[[[[]]]]');
        // 是微信浏览器(在微信内访问链接)
        // 防止地址栏code码没有清除 导致返回的时候 获取不到openid 无法微信支付
        const proUrl = 'https://manage.whha999.com/query/#/wxpay';
        const testUrl = 'http://v1.xinyuyibai.com/query/#/wxpay';
        window.location.href = proUrl + this.wxPayUrl;
      } else {
        //在电脑端访问链接
        let isMobile = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
        try {
          // Toast.loading({
          //   message: '加载中...',
          //   forbidClick: true,
          // });
          let result = await genOrderNo(this.payInfo);
          if (result.data.code == 200) {
            if (this.$route.query.cid == '928' && this.bd_vid) {
              this.payInfo.logidUrl = localStorage.getItem('logidUrl');
            }
            if (isMobile) {
              // 是手机 （控制台调试模式为手机）
              this.payInfo.orderNo = result.data.data;
              this.payInfo.payType = 2;
              this.payInfo.zfbFlag = this.zfbFlag;
              this.pay(this.payInfo);
            } else {
              // 是网页 （控制台调试模式为网页）
              this.payInfo.orderNo = result.data.data;
              this.payInfo.payType = 1;
              this.payInfo.zfbFlag = this.zfbFlag;
              this.pay(this.payInfo);
            }
          }
        } catch (error) {
          Toast(error);
        }
      }
    },
    getAppId() {
      appId().then((res) => {
        if (res.data.code == 200) {
          this.appid = res.data.data;
          // // 根据appid生成code码获取openid
          // this.getWxOpenId();
        } else {
          Toast(res.data.msg);
        }
      });
    },
    // 获取openid~
    getWxOpenId() {
      var ua = navigator.userAgent.toLowerCase();
      // pay 支付需要调用的方法
      if (ua.indexOf('micromessenger') != -1) {
        this.url =
          `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=` +
          encodeURIComponent(
            `https://manage.whha999.com/query/#/toBePaid${this.url}`
          ) +
          '&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect';
        var curl = window.location.href;
        var a = curl.indexOf('code=');
        var b = curl.indexOf('&');
        if (a > 0 && b > -1) {
          this.code = curl.substring(a, b);
        } else if (a > 0 && b == -1) {
          var c = curl.indexOf('#');
          this.code = curl.substring(a, c);
        }
        if (this.code === '' || this.code == null) {
          window.location.href = this.url;
        } else {
          openId({ code: this.code })
            .then((res) => {
              if (res.data.code == 200) {
                if (res.data.data == null) {
                  window.location.href = this.url;
                  return;
                }
                this.payInfo.openid = res.data.data;
              } else {
                Toast(res.data.msg);
              }
            })
            .catch((err) => {
              Toast(err);
            });
        }
      } else {
        console.log('非微信浏览器');
        // 非微信浏览器 微信支付不显示
        this.vxpayshow = false;
      }
    },
    async wxPay() {
      let _this = this;
      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('micromessenger') != -1) {
        try {
          // 获取订单编号
          let result = await genOrderNo(this.payInfo);
          // 成功
          if (result.data.code == 200) {
            this.payInfo.payType = 3;
            this.payInfo.orderNo = result.data.data;
            if (this.$route.query.cid == '928' && this.bd_vid) {
              this.payInfo.logidUrl = localStorage.getItem('logidUrl');
            }
            let _this = this;
            // 执行支付接口
            await tradePay(this.payInfo)
              .then((res) => {
                if (res.data.code == 200) {
                  _this.show = false;
                  pay.callpay(res.data.data);
                  // _this.handelWx(res.data.data);
                } else {
                  Toast(res.data.msg);
                }
              })
              .catch((error) => {
                Toast(error);
              });
          }
        } catch (error) {
          Toast(error);
        }
      } else {
        console.log('外部浏览器');
      }
    },
    //调起微信支付
    handelWx(data) {
      //微信内置浏览器微信支付
      const _this = this;
      let payParams = {
        appId: data.appId,
        timeStamp: data.timeStamp,
        nonceStr: data.nonceStr,
        package: data.package,
        signType: 'MD5',
        paySign: data.paySign,
      };
      WeixinJSBridge.invoke('getBrandWCPayRequest', payParams, function (res) {
        if (res.err_msg === 'get_brand_wcpay_request:ok') {
          // 支付成功的处理逻辑
          _this.latestOrderId({ userId: _this.$route.query.userId });
        } else if (res.err_msg === 'get_brand_wcpay_request:fail') {
          Toast('支付失败');
        } else {
          // uni.showToast({
          // 	title: '支付取消',
          // 	icon: 'none'
          // });
        }
      });
    },
    pay(data) {
      tradePay(data)
        .then((res) => {
          if (res.data.code == 200) {
            // console.log(res.data.data.payType);
            const payType = res.data.data.payType;
            //普通支付宝支付
            if (payType == 1) {
              const div = document.createElement('div');
              /* 此处form就是后台返回接收到的数据 */
              div.innerHTML = res.data.data.link;
              document.body.appendChild(div);
              document.forms[0].submit();
            } else if (payType == 4) {
              //聚合易支付
              window.location.href = res.data.data.link;
            } else if (payType == 5 || payType == 6) {
              //银盛支付 第三方链接
              if (res.data.data.link) {
                //银盛支付 第三方链接
                const details = {
                  orderNo: this.payInfo.orderNo,
                  userId: this.$route.query.userId,
                  // payMoney: this.userInfo.payMoney,
                  link: res.data.data.link,
                };
                if (this.payInfo.payType == 1) {
                  //网页
                  // console.log(encodeURIComponent(JSON.stringify(details)));
                  this.$router.push({
                    path: '/guidPay',
                    query: {
                      details: encodeURIComponent(JSON.stringify(details)),
                    },
                  });
                } else if (this.payInfo.payType == 2) {
                  //手机
                  const userAgent = navigator.userAgent.toLowerCase();
                  //支付宝环境下
                  if (userAgent.indexOf('alipay') !== -1) {
                    // const { href } = this.$router.resolve({
                    //   path: '/guidPay',
                    //   query: {
                    //     details: encodeURIComponent(JSON.stringify(details)),
                    //   },
                    // });
                    // window.open(href, '_blank');
                    console.log(details.link);
                    ap.pushWindow({
                      url: details.link, //(使用jump这个参数生成的支付链接)
                      success: (res) => {
                        console.log(res);
                      },
                      fail: (err) => {
                        console.log(err);
                      },
                    });
                  } else if (userAgent.indexOf('baiduboxapp') !== -1) {
                    window.location.href = res.data.data.payUrl; //(使用pay_url这个参数生成的支付链接)
                    //使用pay_url参数生成的支付链接打开 可以调起支付宝支付 但是支付成功之后 不能跳转到我们的报告页 使用jump这个参数生成的支付链接打开 无法调起支付宝支付
                  } else {
                    //普通浏览器
                    window.location.href = res.data.data.link; //(使用jump这个参数生成的支付链接)
                  }
                }
              } else {
                Toast('支付链接有误');
              }
            }
          } else {
            Toast(res.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showBtn() {
      this.show = false;
      this.showDialo = true;
    },
    jxcxbtn() {
      this.showDialo = false;
      this.show = true;
    },
  },
};
</script>

<style lang="less" scoped>
.boss {
  width: 100vw;
  min-height: 100vh;
  background: #f3f4f8;
  padding-bottom: 120px;
}
.header {
  width: 100%;
  height: 452px;
  background-image: url('../assets/image/header.png');
  background-size: cover;
  background-position: 100% 100%;
  overflow: hidden;
  &-box {
    width: 100%;
    height: 100px;
    // background: red;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-evenly;
    margin-top: 100px;
    .divp {
      margin-left: 30px;
      color: #fff;
      .p1 {
        width: 350px;
        // background: red;
        font-size: 50px;
        span {
          position: relative;
          top: 20px;
          font-size: 50px;
          color: white;
          animation: change 0.3s infinite alternate;
        }
        @keyframes change {
          100% {
            top: -10px;
          }
        }
        span:nth-child(2) {
          animation-delay: 0.2s;
        }
        span:nth-child(3) {
          animation-delay: 0.5s;
        }
        span:nth-child(4) {
          animation-delay: 0.9s;
        }
        span:nth-child(5) {
          animation-delay: 1.4s;
        }
        span:nth-child(6) {
          animation-delay: 2s;
        }
        span:nth-child(7) {
          animation-delay: 2.4s;
        }
        span:nth-child(8) {
          animation-delay: 2.8s;
        }
      }
      .p2 {
        font-size: 24px;
      }
    }
    .imgbox {
      width: 300px;
      height: 300px;
      position: relative;
      .dwp {
        width: 80px;
        height: 80px;
        line-height: 80px;
        text-align: center;
        background: #fff;
        opacity: 0.9;
        border-radius: 50%;
        color: #000;
        font-size: 24px;
        position: absolute;
        top: calc(50% - 40px);
        left: calc(50% - 40px);
      }
      img {
        width: 300px;
        height: 300px;
        animation: rotate 3s ease;
      }
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(2160deg);
        }
      }
    }
  }

  .report {
    text-align: center;
    color: #fff;
    margin-top: 90px;
    opacity: 0.8;
  }
}
.dwitem {
  width: 92%;
  min-height: 850px;
  background: #fff;
  margin: 0 auto;
  border-radius: 20px;
  margin-top: -105px;
  overflow: hidden;
}
.swperitem {
  width: 92%;
  height: 80px;
  margin: 0 auto;
  margin-top: 30px;
}
.userData {
  width: 92%;
  height: 100px;
  line-height: 50px;
  margin: 0 auto;
  font-size: 24px;
  font-weight: bold;
  text-indent: 50px;
  margin-bottom: 10px;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.notice-swipe {
  height: 50px;
  line-height: 50px;
}
::v-deep .van-notice-bar {
  width: 88%;
  height: 50px;
  border-radius: 30px !important;
  background: #fff6ef;
  margin: 0 auto;
}
.example {
  width: 100%;
  height: 50px;
  // background: pink;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    width: 150px;
    height: 20px;
    line-height: 20px;
    font-size: 24px;
    text-align: center;
  }
  p:nth-child(1) {
    border-right: 1px solid #ccc;
  }
}
/* 小屏手机 320px */
@media all and (max-width: 320px) {
  .cntboss {
    width: 90%;
  }
}
/* 中屏手机 大于320px */
@media all and (min-width: 321px) {
  .cntboss {
    width: 80%;
  }
}

.cntboss {
  margin: 0 auto;
  .cntbox {
    width: 100%;
    height: 50px;
    // background: red;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    .cntboxl {
      display: flex;
      align-items: center;
      font-size: 22px;
      img {
        width: 28px;
        height: 28px;
        margin-right: 10px;
      }
    }
    .cntboxr {
      display: flex;
      align-items: center;
      color: #197bff;
      img {
        width: 28px;
        margin-right: 10px;
        // height: 48px;
      }
    }
  }
}
.more {
  width: 100%;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: #568af3;
}
.payment {
  width: 92%;
  height: 88px;
  line-height: 88px;
  background: linear-gradient(90deg, #f8a500, #e67300) !important;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  border-radius: 10px;
  font-size: 30px;
  position: fixed;
  bottom: 100px;
  left: 4%;
}
.block {
  width: 76%;
  min-height: 200px;
  background-color: #fff;
  margin: 60% auto;
  border-radius: 20px;
  padding: 0 20px 30px 20px;
  .block1 {
    height: 66px;
    font-size: 32px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
  }
  .block2 {
    height: 88px;
    // background: pink;
    margin-top: 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    img {
      width: 72px;
      height: 72px;
      margin-left: 20px;
    }
    .block2_r {
      height: 88px;
      .block2_r_one {
        height: 44px;
        display: flex;
        align-items: center;
        margin-left: 20px;
        font-size: 26px;
        font-weight: 600;
        img {
          width: 44px;
          height: 35px;
        }
      }
      .block2_r_two {
        display: flex;
        align-items: center;
        img {
          width: 19px;
          height: 23px;
          margin-right: 10px;
        }
      }
    }
  }
  .block3 {
    height: 88px;
    // background: pink;
    margin-top: 15px;
    display: flex;
    align-items: center;
    // border-bottom:1px solid #eee ;
    img {
      width: 72px;
      height: 72px;
      margin-left: 20px;
    }
    .block3_r {
      height: 88px;
      .block3_r_one {
        height: 44px;
        display: flex;
        align-items: center;
        margin-left: 20px;
        font-size: 26px;
        font-weight: 600;
        img {
          width: 44px;
          height: 35px;
        }
      }
      .block3_r_two {
        display: flex;
        align-items: center;
        img {
          width: 19px;
          height: 23px;
          margin-right: 10px;
        }
      }
    }
  }
}
.dialogbox {
  width: 100%;
  height: 150px;
  // background: red;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  div {
    img {
      width: 50px;
      height: 50px;
    }
    p {
      font-weight: 600;
      font-size: 26px;
    }
  }
}
.dialogboxText {
  text-align: center;
  width: 90%;
  height: 60px;
  line-height: 60px;
  background: #fff3e9;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 25px;
  color: #ff6e22;
  margin-bottom: 50px;
  font-weight: 600;
}
.footer {
  width: 100%;
  font-size: 12px;
  color: #666;
  text-align: center;
  position: fixed;
  bottom: 10px;
  left: 0;
}
</style>
