import City from "./City"

    export const getCardInfo = (Card)=>{
        if(Card==undefined||Card==""||!Card){
            let data = {
                address:"********",
                age:"********",
                sex:"********"
            } 
            return data
        }
        let province = Card.slice(0,2)+"0000"
        let city = Card.slice(0,4)+"00"
        let county = Card.slice(0,6)
        let userinfo = ""
        if(City.province_list[province]){
            userinfo+=City.province_list[province]
        }
        if( City.city_list[city]){
            userinfo+= City.city_list[city]
        }
        if(City.county_list[county]){
            userinfo+=City.county_list[county]
        }
        let sex = Card.slice(16,17)%2==1?"男":"女"

        let myDate = new Date()
        let month = myDate.getMonth() + 1
        let day = myDate.getDate()
        let age = myDate.getFullYear()-Card.substring(6, 10) - 1
        if (Card.substring(10,12)<month||Card.substring(10,12)==month&&Card.substring(12,14)<=day) { 
            age++ 
        } 
        let data = {
            address:userinfo,
            age:age,
            sex:sex
        }
        return data
    }
